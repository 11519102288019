import React from "react";
import "./Footer.css";
import Logo from "../../img/logo.png";

export default function Footer() {
  return (
    <div>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-5">
              {/* <img src={Logo} width="50%" /> */}
              <h2>
                Lets develop
                <br /> software together!
              </h2>
              <p>
                Be the first to know about
                <br />
                IT innovations and interesting case studies.
              </p>

              <div>
                <div>
                  <input />
                  <button>SUBSCRIBE</button>
                </div>
                <br />
                <p className="agree">
                  By signing up you agree to our Terms of Use and privacy <br />
                  policy, including the use of cookies and transfer of your
                  personal information.
                </p>
              </div>
            </div>
            <div className="col">
              <div className="Footer_Tab">
                <div className="row">
                  <div className="col">
                    <p>Home</p>
                    <p>service</p>
                    <p>about us</p>
                    <p>contact us</p>
                  </div>
                  <div className="col">
                    <p>website development</p>
                    <p>website hosting</p>
                    <p>system development</p>
                    <p>graphic design</p>
                  </div>
                  <div className="col">
                    <p>Get a quote</p>
                    <p>email us</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col"></div>
                  <div className="col"></div>
                  <div className="col"></div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="ft">
            <div className="row">
              <div className="col-3">Speedtech Rights.</div>
              <div className="col color_grey">
                © 2022-2023 Speedexpress technology. All Rights Reserved.
                Privacy Policy. Deira Tower 202, Dubai.
              </div>
              <div className="col" align="right">
                <a href=" " target="_blank">
                  <i className="ti-facebook"></i>
                </a>
                <a href=" " target="_blank">
                  <i className="ti-twitter"></i>
                </a>
                <a href=" " target="_blank">
                  <i className="ti-github"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
