import React from "react";
import "./Header.css";
import Logo from "../../img/logo.png";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <div className="container">
      <header className="navigation">
        <div className="header-top is-hidden-mobile">
          <div className="container">
            <div className="columns is-justify-content-space-between is-align-items-center">
              <div className="column is-9">
                <div className="header-top-info">
                  <a href="tel:+23-345-67890">
                    <i className="fa fa-phone mr-2"></i>
                    <span>+971 - 123 456 789</span>
                  </a>
                  <a href="mailto:support@gmail.com">
                    <i className="fa fa-envelope mr-2"></i>
                    <span>support@speedexpress.tech</span>
                  </a>
                </div>
              </div>
              <div className="column is-3">
                <div className="header-top-socials has-text-centered has-text-right-tablet">
                  <a href=" " target="_blank">
                    <i className="ti-facebook"></i>
                  </a>
                  <a href=" " target="_blank">
                    <i className="ti-twitter"></i>
                  </a>
                  <a href=" " target="_blank">
                    <i className="ti-github"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-4">
            <img src={Logo} width="60%" />
          </div>
          <div className="col">
            <div className="headerMenu">
              <ul>
                <li>
                  <Link to="/5">Home </Link>
                </li>
                <li>
                  <a href="#about">About us</a>
                </li>
                <li>
                  <a href="#services">Services</a>
                </li>
                <li>
                  <Link to="/Contact">Contact Us</Link>
                </li>
                <li>
                  <button
                    className="btn btn-primary"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    GET A QUOTE
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
