import React from "react";
import "./About.css";
import COM from "../../img/comp.png";

export default function About() {
  return (
    <div id="about">
      <div className="container">
        <div className="about">
          <h2>about us</h2>
          <div className="row">
            <div className="col-4 compside">
              <img src={COM} width="130%"/>
            </div>
            <div className="col">
              <div className="_about_head">
                <p>
                  Our software company is a leading provider of innovative
                  software solutions for businesses and organizations around the
                  world. We specialize in developing custom software solutions
                  that are tailored to meet the unique needs and requirements of
                  our clients, and we are committed to delivering high-quality
                  products that help our clients succeed in today's
                  ever-changing business landscape.
                </p>
              </div>
              <div className="row">
                <div className="col">
                  <div className="row">
                    <div className="col-3 ionside">
                      <ion-icon name="desktop-outline"></ion-icon>
                    </div>
                    <div className="col">
                      {" "}
                      <h6>Our vision</h6>
                      <p>
                        Our vision is to build long-term relationships with our
                        clients, working closely with them to understand their
                        needs and goals and deliver software solutions that
                        exceed their expectations
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="row">
                    <div className="col-3 ionside">
                      <ion-icon name="document-lock-outline"></ion-icon>
                    </div>
                    <div className="col">
                      <h6>Our mission</h6>
                      <p>
                        We are dedicated to staying up-to-date with the latest
                        trends and best practices in software development, and
                        we are constantly exploring new ways to improve our
                        products and services
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="row">
                    <div className="col-3 ionside">
                      <ion-icon name="planet-outline"></ion-icon>
                    </div>
                    <div className="col">
                      <h6>Who are we</h6>
                      <p>
                        Our vision is to build long-term relationships with our
                        clients, working closely with them to understand their
                        needs and goals and deliver software solutions that
                        exceed their expectations
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="row">
                    <div className="col-3 ionside">
                      <ion-icon name="pulse-outline"></ion-icon>
                    </div>
                    <div className="col">
                      <h6>Where are we</h6>
                      <p>
                        We are dedicated to staying up-to-date with the latest
                        trends and best practices in software development, and
                        we are constantly exploring new ways to improve our
                        products and services
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
