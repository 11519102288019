import React from "react";
import "./Main.css";

export default function Main() {
  return (
    <div>
      <section className="slider">
        <div className="container">
          <div className="content">
            <h1>Innovative Software Solutions for Your Business</h1>
            <label>Empowering businesses with cutting-edge technology</label>
            <br />
            <br />
            <br />
            {/* <button
              className="btn btn-primary"
              style={{
                fontWeight: "bold",
              }}
            >
              GET QUOTES
            </button> */}
            <button
              className="btn btn-primary"
              style={{
                background: "brown",
                border: "none",
                fontWeight: "bold",
              }}
            >
              <a href="#services">SERVICES</a>
            </button>
          </div>
        </div>
      </section>
      <div className="flm">
        <div className="container">
          <div className="row">
            <div className="col col-6 dx">
              <div>
                Schedule Meeting
                <ion-icon name="arrow-forward-outline"></ion-icon>
              </div>
            </div>
            <div className="col">
              <div className="Float">
                <div className="row">
                  <div className="col col-6">
                    <ion-icon name="call-outline"></ion-icon> Call Now
                    +123-456-789
                  </div>
                  <div className="col">
                    GET A FREE QUOTE{" "}
                    <ion-icon name="arrow-forward-outline"></ion-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
