import Header from "./Component/Header/Header";
import Main from "./Component/Main/Main";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import About from "./Component/About/About";
import Sub from "./Component/Sub/Sub";
import Footer from "./Component/Foooter/Footer";
import SoftwareServices from "./Component/Services/Services";
import Contact from "./Component/Contact/Contact";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Subsribe from "./Component/Subscribe/Subsribe";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/Contact">
            <Header />
            <Contact />
            <Footer />
          </Route>
          <Route path="/">
            <div className="topbg">
              <div className="tp">
                <Header />
                <Main />
              </div>
            </div>
            <br />
           
            {/* <Sub /> */}
            <About />
            <Subsribe />
            <SoftwareServices />
            <Footer />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
